import React from "react";

const IconFigma = () => (
  <svg
    width="26"
    height="38"
    viewBox="0 0 26 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeLineJoin="round"
    strokeLinecap="round"
  >
    <title>Figma</title>
    <path d="M19 25C22.3137 25 25 22.3137 25 19C25 15.6863 22.3137 13 19 13C15.6863 13 13 15.6863 13 19C13 22.3137 15.6863 25 19 25Z" />
    <path d="M13 12.9998V1H7C5.4087 1 3.88258 1.63214 2.75736 2.75736C1.63214 3.88258 1 5.4087 1 7C1 8.5913 1.63214 10.1174 2.75736 11.2426C3.88258 12.3679 5.4087 13 7 13L13 12.9998Z" />
    <path d="M13 24.9998V12.9999H7C5.4087 12.9999 3.88258 13.6321 2.75736 14.7573C1.63214 15.8825 1 17.4087 1 19C1 20.5913 1.63214 22.1174 2.75736 23.2426C3.88258 24.3679 5.4087 25 7 25L13 24.9998ZM13 12.9999V1H19C20.5913 1 22.1174 1.63214 23.2426 2.75737C24.3679 3.8826 25 5.40873 25 7.00004C25 8.59135 24.3679 10.1175 23.2426 11.2427C22.1174 12.3679 20.5913 13.0001 19 13.0001L13 12.9999Z" />
    <path d="M13 25V31C13 32.1867 12.6481 33.3467 11.9889 34.3334C11.3296 35.3201 10.3925 36.0891 9.29616 36.5433C8.1998 36.9974 6.9934 37.1162 5.82951 36.8847C4.66562 36.6532 3.59651 36.0818 2.75739 35.2427C1.91827 34.4036 1.34681 33.3345 1.1153 32.1706C0.883779 31.0068 1.00259 29.8004 1.45672 28.704C1.91084 27.6077 2.67988 26.6706 3.66657 26.0113C4.65327 25.352 5.81331 25.0002 7 25.0002L13 25Z" />
  </svg>
);

export default IconFigma;
